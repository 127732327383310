import { MigrationManifest } from 'redux-persist/es/types'

import { DeviceType } from '@/graphql/generated/schemas'

import { initialState as initialIncidentsState } from './incidents/incidentsSlice'
import { AppState } from './store'

export const migrations: MigrationManifest = {
  0: (state: AppState) => state,
  2: (state: AppState) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        sops: {
          ...state.ui.sops,
          filters: {
            nameLike: null,
            facilityId: null,
            createdAtRange: null,
            creatorId: null,
          },
          pageSize: 25,
        },
      },
    }
  },
  3: (state: AppState) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        drawers: {
          incident: {
            id: null,
            isOpen: false,
          },
          device: {
            id: null,
            deviceType: DeviceType.Camera,
            isOpen: false,
          },
          sop: {
            id: null,
            isOpen: false,
          },
        },
      },
    }
  },
  4: (state: AppState) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        notifications: {
          showUnread: false,
        },
        drawers: {
          ...state.ui?.drawers,
          sopIncident: {
            id: null,
            isOpen: false,
            sopNameLike: null,
          },
        },
      },
    }
  },
  5: (state: AppState) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        emergencyCall: {
          isOpen: false,
          isExpanded: true,
          isMuted: false,
          incident: {
            id: null,
            displayId: null,
            facilityName: null,
          },
        },
      },
    }
  },
  6: (state: AppState) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        outstandingIncidents: {
          ...state.ui.outstandingIncidents,
          filters: {
            facilities: [],
            deviceType: [],
            incidentTypes: [],
            guards: [],
            operators: [],
            timeOrder: [],
          },
          pageSize: 25,
        },
      },
    }
  },
  7: (state: AppState) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        drawers: {
          ...state.ui?.drawers,
          submittedTour: {
            id: null,
            isOpen: false,
          },
        },
      },
    }
  },
  8: (state: AppState): AppState => {
    return {
      ...state,
      ui: {
        ...state.ui,
        gatewayDevices: {
          pageSize: 25,
          filters: {
            nameLike: null,
          },
        },
      },
    }
  },
  9: (state: AppState): AppState => {
    return {
      ...state,
      incidents: initialIncidentsState,
    }
  },
  10: (state: AppState): AppState => {
    return {
      ...state,
      incidents: initialIncidentsState,
    }
  },
}
