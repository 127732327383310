import { useEffect } from 'react'

import { useSavedFilter } from './useSavedFilter'
import { useSelector } from 'react-redux'

import {
  useFloorsByFacilityIdsQuery,
  useIncidentFiltersEnrichmentQuery,
} from '@/graphql/generated/hooks'
import { DevicesQuery } from '@/graphql/generated/operations'
import { buildFormState } from '@/redux/incidents/conversion'
import {
  createdAtIntervalOptions,
  incidentStatusOptions,
  mutedOptions,
  priorityOptions,
  sourceTypeOptions,
} from '@/redux/incidents/incidentsFilterOptions'
import { selectActiveFilterCriteria } from '@/redux/incidents/incidentsSlice'
import { getRelatedFloors } from '@/utils/filterDrawers'
import {
  OptionI,
  getDeviceOptions,
  getFacilityOptions,
  getFloorOptions,
  getIncidentTypeOptions,
} from '@/utils/forms/selectOptions'

import { SavedFilterI } from '../types/generic'

interface UseIncidentFilterFormValuesIProps {
  watch: any
  setValue: any
  editedFilter: SavedFilterI | null
}

export const useIncidentFilterFormValues = ({
  watch,
  setValue,
  editedFilter,
}: UseIncidentFilterFormValuesIProps) => {
  // In regular form state this retrieves criteria saved in Redux state
  // In update filter form this mimics filter state for selected filter
  const criteria = editedFilter
    ? useSavedFilter({ savedFilter: editedFilter })
    : useSelector(selectActiveFilterCriteria)

  const { data: enrichmentData } = useIncidentFiltersEnrichmentQuery({
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    skip: !criteria,
  })

  const { data: floorsData, refetch } = useFloorsByFacilityIdsQuery({
    fetchPolicy: 'network-only',
    variables: { ids: criteria?.facilityIdIn },
    skip: !criteria,
  })

  const isLoading = !criteria || !enrichmentData || !floorsData
  const watchFacility: OptionI[] = watch('facilityName', criteria?.facilityIdIn)
  const watchFloor: OptionI[] = watch('floorName')

  useEffect(() => {
    if (watchFacility && watchFacility[0]?.value) {
      const ids = watchFacility.map((e) => e.value)
      refetch({ ids })
    }

    if (watchFacility && watchFloor) {
      // Set the floor values with only values from selected facilities
      const validFloors = getRelatedFloors(watchFacility, watchFloor)
      setValue('floorName', validFloors)
    }
  }, [watchFacility])

  const options = {
    createdAtOptions: createdAtIntervalOptions,
    deviceNameOptions: getDeviceOptions(enrichmentData as DevicesQuery),
    facilityNameOptions: getFacilityOptions(enrichmentData),
    floorNameOptions: watchFacility?.length ? getFloorOptions(floorsData) : [],
    incidentStatusOptions: incidentStatusOptions,
    incidentTypeOptions: getIncidentTypeOptions(enrichmentData),
    mutedOptions: mutedOptions,
    priorityOptions: priorityOptions,
    sourceTypeOptions: sourceTypeOptions,
  }

  const defaultValues = buildFormState(criteria, {
    ...enrichmentData,
    ...floorsData,
  })

  return isLoading
    ? { defaultValues: null, options: null }
    : { defaultValues, options }
}
