import { useCall } from '../hooks/useCall'

import { Button, Tooltip, useDisclosure } from '@chakra-ui/react'

import { EmergencyCall } from '@/components/icons'
import { MixpanelDataIProps } from '@/features/incident-drawer'
import { IncidentQuery } from '@/graphql/generated/operations'

import { ConfirmEmergencyCallDialog } from './ConfirmEmergencyCallDialog'

interface EmergencyCallButtonIProps {
  incident: IncidentQuery['incident']
  mixpanelData?: MixpanelDataIProps
}

export const EmergencyCallButton = ({
  incident,
  mixpanelData,
  ...props
}: EmergencyCallButtonIProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { isOpen: isCallOpen, openCall } = useCall()

  const bgColor = '#e3eefb'
  const hoverBgColor = '#1b253f'

  const handleCallClick = () => {
    openCall(
      {
        incidentId: incident?.id,
        incidentDisplayId: incident?.displayId,
        facilityName: incident?.facility?.name,
        emergencyNumber: incident?.facility?.emergencyNumber,
      },
      mixpanelData
    )
    onClose()
  }

  return (
    <>
      <Tooltip
        arrow={hoverBgColor}
        bg={hoverBgColor}
        color='white'
        hasArrow
        label='Emergency Call'
        mt='5px'
        openDelay={250}
        p='10px'
      >
        <Button
          _disabled={{
            opacity: 0.4,
            cursor: 'not-allowed',
            _hover: {
              bgColor: bgColor,
            },
          }}
          _focus={{ bgColor: isCallOpen ? 'auto' : bgColor }}
          _hover={{ bgColor: isCallOpen ? 'auto' : hoverBgColor }}
          bgColor={bgColor}
          border='none'
          color='#E53E3E'
          data-testid='EmergencyCall:button'
          disabled={isCallOpen}
          fontSize='20px'
          ml='5px'
          onClick={onOpen}
          width='82px'
          {...props}
        >
          <EmergencyCall strokeColor='#E53E3E' strokeWidth={4} />
        </Button>
      </Tooltip>
      {isOpen && (
        <ConfirmEmergencyCallDialog
          emergencyNumber={incident?.facility?.emergencyNumber}
          handleOnClick={handleCallClick}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </>
  )
}
