import {
  DeviceType,
  IncidentPriority,
  IncidentSource,
  IncidentStatus,
  IncidentType,
} from '@/graphql/generated/schemas'

export const incidentStatusOptions = [
  { value: IncidentStatus.Active, label: 'Active', color: '#D01030' },
  { value: IncidentStatus.InProgress, label: 'In Progress', color: '#D16A1E' },
  { value: IncidentStatus.Resolved, label: 'Resolved', color: '#29D11E' },
]

export const sourceTypeOptions = [
  { value: DeviceType.Camera, label: 'Camera' },
  { value: DeviceType.Door, label: 'Access Control Door' },
  { value: IncidentSource.Employee, label: 'Manual' },
  { value: IncidentSource.Guard, label: 'Guard' },
]

export const priorityOptions = [
  { value: IncidentPriority.High, label: 'High', color: '#d01030' },
  { value: IncidentPriority.Medium, label: 'Medium', color: '#FFC400' },
  { value: IncidentPriority.Low, label: 'Low', color: '#36B37E' },
]

export const incidentTypeOptions = [
  { value: IncidentType.DoorHeldClose, label: 'Door Held Close' },
  { value: IncidentType.DoorHeldOpen, label: 'Door Held Open' },
  { value: IncidentType.ForcedEntry, label: 'Door Forced' },
  { value: IncidentType.LineCrossing, label: 'Line Crossing' },
  { value: IncidentType.Tailgating, label: 'Tailgating' },
]

const gteInterval = (days: number) => days * 24 * 3600

export const createdAtIntervalOptions = [
  { value: gteInterval(1), label: 'Last 24 hours' },
  { value: gteInterval(2), label: 'Last 2 Days' },
  { value: gteInterval(7), label: 'Last 7 Days' },
  { value: gteInterval(30), label: 'Last 30 Days' },
]

export const raisedOption = { value: false, label: 'Alarmed' }
export const mutedOption = { value: true, label: 'Muted' }
export const mutedOptions = [mutedOption, raisedOption]
