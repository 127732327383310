import { Box, Spinner } from '@chakra-ui/react'

export const TableSpinner = ({ height }: { height: number }) => {
  return (
    <Box as='tr' height={height} placeItems='center'>
      <Box as='td' colSpan={99}>
        <Spinner
          color='#298bbd'
          emptyColor='gray.300'
          size='md'
          thickness='4px'
        />
      </Box>
    </Box>
  )
}
