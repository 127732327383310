import {
  CasesQuery,
  DevicesQuery,
  FacilityTypesQuery,
  ManualIncidentCategoriesQuery,
  OutstandingIncidentsGuardsQuery,
  OutstandingIncidentsOperatorsQuery,
  PacsSourcesQuery,
  TechnicianQuery,
  TechniciansQuery,
} from '@/graphql/generated/operations'
import {
  FacilityFloorsQuery,
  StandardOperatingProceduresFilterDataQuery,
} from '@/graphql/generated/operations'
import { CaseStatus } from '@/graphql/generated/schemas'
import { incidentTypeOptions } from '@/redux/incidents/incidentsFilterOptions'

import { getUniqueValues } from '../filterDrawers'
import { getArchivedLabelWithSN, getUserRoleSuffix } from '../formats'
import {
  FacilityT,
  GenericFacilitiesQuery,
  GenericUsersQuery,
  UserT,
} from './types'

export interface OptionI<T = string> {
  label: string
  value: T
}

type Options = Array<OptionI>

export const getIncidentFacilityOptions = (
  facilitiesData: FacilityTypesQuery
): Options =>
  facilitiesData?.facilities?.edges.map((f) => ({
    label: `${f?.node?.name} - (${f?.node?.shortName})`,
    value: f?.node?.id,
  })) || []

export const getFacilityOptions = (
  facilities: GenericFacilitiesQuery
): Options =>
  facilities?.facilityNames?.edges.map((f) => getFacilityOption(f?.node)) || []

export const getTechnicianFacilityOptions = (
  techData: TechnicianQuery
): Options =>
  techData?.technician?.facilities?.map((f) => getFacilityOption(f)) || []

export const getFacilityOption = (facility: FacilityT): OptionI => ({
  label: getArchivedLabelWithSN(facility),
  value: facility?.id,
})

export const getFloorOptions = (floorsData: FacilityFloorsQuery): Options =>
  floorsData?.floors?.edges.map((f) => ({
    label: `${f?.node?.name} - (${f?.node?.facility?.name})`,
    value: f?.node?.id,
  })) || []

export const getDeviceOptions = (devicesData: DevicesQuery): Options =>
  devicesData?.devices?.edges.map((f) => ({
    label: `${f?.node?.name} - (${f?.node?.facility?.name})`,
    value: f?.node?.id,
  })) || []

export const getPacsOptions = (pacsData: PacsSourcesQuery): Options =>
  pacsData?.pacsSources?.edges.map((f) => ({
    label: `${f?.node?.name}`,
    value: f?.node?.id,
  })) || []

export const getUserOption = (user: UserT): OptionI => ({
  label: `${user?.firstName || ''} ${user?.lastName || ''}${
    user?.roleName ? getUserRoleSuffix(user.roleName) : ''
  }`,
  value: user.id,
})

export const getUserOptions = (usersData: GenericUsersQuery): Options =>
  usersData?.users?.edges.map((e) => e?.node).map(getUserOption) || []

export const getSOPCreatorOptions = (
  sopData: StandardOperatingProceduresFilterDataQuery
): Options => {
  const sopCreators = sopData?.standardOperatingProcedures.edges.map((s) => ({
    label: `${s?.node?.creator?.firstName} ${s?.node?.creator?.lastName}`,
    value: s?.node?.creator.id,
  }))
  return sopCreators ? getUniqueValues(sopCreators) : []
}

export const getTechnicianOptions = (data: TechniciansQuery): Options =>
  data?.technicians?.edges.map((f) => ({
    label: `${f?.node?.firstName} ${f?.node?.lastName} (${f?.node?.email})`,
    value: f?.node?.id,
  })) || []

export const getOutstandingIncidentsGuardsOptions = (
  data: OutstandingIncidentsGuardsQuery
): Options =>
  data?.guards?.edges.map((f) => ({
    label: `${f?.node?.firstName} ${f?.node?.lastName}`,
    value: f?.node?.id,
  })) || []

export const getOutstandingIncidentsOperatorsOptions = (
  data: OutstandingIncidentsOperatorsQuery
): Options =>
  data?.operators?.edges.map((f) => ({
    label: `${f?.node?.firstName} ${f?.node?.lastName}`,
    value: f?.node?.id,
  })) || []

export const getIncidentCategoryOptions = (
  data: ManualIncidentCategoriesQuery
) =>
  data?.manualIncidentCategories?.edges?.map((e) => ({
    label: e?.node?.name,
    value: e?.node?.id,
  })) || []

export const getIncidentTypeOptions = (data: ManualIncidentCategoriesQuery) =>
  sortOptions([...getIncidentCategoryOptions(data), ...incidentTypeOptions])

export const sortOptions = <T>(options: OptionI<T>[]): OptionI<T>[] => {
  return [...options].sort((a, b) => a.label.localeCompare(b.label))
}

export const getDefaultOption = <T>(
  option: OptionI<T> | OptionI<T>[]
): OptionI<T> | OptionI<T>[] | null => {
  if (Array.isArray(option)) {
    return option?.length ? option : null
  } else {
    return option || null
  }
}

export const mapOptions = <T>(arr: T[]): OptionI<T>[] =>
  arr.map((optionValue) => ({
    label: optionValue as string,
    value: optionValue,
  }))

export const getCaseOwnerOptions = (caseData: CasesQuery): Options => {
  const ownerOptions = caseData?.cases?.edges.map((c) => ({
    label: `${c?.node?.owner?.firstName} ${c?.node?.owner?.lastName}`,
    value: c?.node?.owner.id,
  }))
  return ownerOptions ? getUniqueValues(ownerOptions) : []
}

export const caseStatusOptions = [
  { value: CaseStatus.Active, label: 'Active', color: '#931200' },
  { value: CaseStatus.Closed, label: 'Closed', color: '#29D11E' },
]

export const caseUpdateStatusOptions = [
  { value: CaseStatus.Active, label: 'Active' },
  { value: CaseStatus.Closed, label: 'Closed' },
]

export const getDefaultCaseLabel = (caseStatus: CaseStatus) => {
  return caseUpdateStatusOptions.find((i) => i.value === caseStatus).label
}

export const getCaseOptions = (caseData: CasesQuery): Options =>
  caseData?.cases?.edges.map((c) => ({
    label: `${c?.node?.name}`,
    value: c?.node?.id,
  })) || []
